/* global DMP_JS_PROPS */

import { Calendar } from '@fullcalendar/core';
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import luxon2Plugin from '@fullcalendar/luxon2';
import timeGridPlugin from '@fullcalendar/timegrid';
import tippy from 'tippy.js';

const dmpCalendar = {
  init: async (calendarEl) => {
    const events = await dmpCalendar.getEvents();
    new Calendar(calendarEl, {
      plugins: [luxon2Plugin, dayGridPlugin, timeGridPlugin, listPlugin],
      // TODO get timezone value from Django settings, same as URLs
      timeZone: 'Europe/Berlin',
      // TODO get current locale from browser or Django
      locale: deLocale,
      initialView: 'dayGridMonth',
      weekNumbers: true,
      nowIndicator: true,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listMonth',
      },
      events,
      eventDidMount: (info) => {
        if (info.view.type === 'listMonth') {
          return;
        }
        tippy(info.el, {
          content: `<strong>${info.event.title}</strong>`,
          allowHTML: true,
          delay: 200,
          // TODO set trigger to click/tap/focus, show modal of event details
          // with link to detail page (need to remove a:href from calendar
          // event for that)
          trigger: 'mouseenter focus',
        });
      },
    }).render();
  },
  getEvents: async () => {
    // Get events in JSON format from API URL
    const url = DMP_JS_PROPS.URLS.GETEVENTS;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => data);
  },
};

export default dmpCalendar;
