const dmpRedirect = {
  isExternalUrl: (currentPage, url) => {
    let currentURL = url;
    let newURL = '';
    if (url.indexOf('//') === 0) {
      /* eslint-disable no-restricted-globals */
      currentURL = location.protocol + url;
    }
    try {
      newURL = new URL(currentURL);
    } catch (TypeError) {
      // TODO check why sometimes TypeError occurs
      // (TypeError: URL Constructor: "# is not valid URL")
      return false;
    }
    if (['mailto:', 'tel:'].includes(newURL.protocol)) {
      return false;
    }
    return newURL.origin !== currentPage.origin;
  },
  hideModal: () => {
    const modalEl = document.getElementById('js-redirect');
    modalEl.classList.remove('redirect-modal--visible');
  },
  resetModal: () => {
    const modalLink = document.getElementById('js-redirect-link');
    modalLink.setAttribute('href', '');
    modalLink.innerHTML = '';
  },
  showModal: (targetUrl) => {
    const modalEl = document.getElementById('js-redirect');
    const modalLink = document.getElementById('js-redirect-link');
    modalLink.setAttribute('href', targetUrl);
    modalLink.innerHTML = targetUrl;
    modalEl.classList.add('redirect-modal--visible');
  },
};

export default dmpRedirect;
