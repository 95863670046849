const dmpNavigation = {
  // init: (menuItems) => {
  //   Array.prototype.forEach.call(menuItems, (el, idx) => {
  //     el.addEventListener('mouseover', () => {
  //       el.classList.add('open');
  //       if (typeof timer !== 'undefined') {
  //         clearTimeout(timer);
  //       }
  //     });
  //     el.addEventListener('mouseout', () => {
  //       timer = setTimeout(() => {
  //         el.classList.remove('open');
  //       }, 1000);
  //     });
  //   });
  // },
  closeNavigation: () => {
    if (document.body.classList.contains('site-navigation-open')) {
      document.body.classList.remove('site-navigation-open');
    }
  },
  toggleNavigation: () => {
    if (document.body.classList.contains('site-navigation-open')) {
      document.body.classList.remove('site-navigation-open');
    } else {
      document.body.classList.add('site-navigation-open');
    }
  },
};

export default dmpNavigation;
