import { Splide } from '@splidejs/splide';

const dmpGalleries = {
  el: {
    lightbox: null,
    lightboxSlider: null,
    lightboxTriggerEl: null,
  },
  init: (sliders) => {
    for (let i = 0; i < sliders.length; i += 1) {
      if (sliders[i].classList.contains('gallery-slider--thumbnails')) {
        dmpGalleries.initializeThumbnailSlider(sliders[i]);
      } else {
        dmpGalleries.initializeRegularSlider(sliders[i]);
      }
    }
  },
  initializeLightbox: () => {
    // Find gallery images
    const galleryItems = document.querySelectorAll(
      '.splide.lightbox .splide__slide a',
    );
    if (galleryItems.length === 0) {
      return;
    }

    // Create the lightbox HTML element
    const lightbox = document.createElement('div');
    lightbox.setAttribute('class', 'splide');
    lightbox.setAttribute('id', 'dmp-lightbox');
    lightbox.setAttribute('aria-hidden', true);
    lightbox.setAttribute('tabindex', '-1');
    const lightboxTrack = document.createElement('div');
    lightboxTrack.setAttribute('class', 'splide__track');
    const lightboxList = document.createElement('ul');
    lightboxList.setAttribute('class', 'splide__list');
    const lightboxClose = document.createElement('button');
    lightboxClose.setAttribute('id', 'dmp-lightbox-close');
    lightboxClose.setAttribute('type', 'button');
    lightboxClose.setAttribute('aria-label', 'Close lightbox');
    lightboxClose.setAttribute('aria-controls', 'dmp-lightbox');
    lightboxClose.innerHTML = 'X';
    lightboxTrack.appendChild(lightboxList);
    lightbox.appendChild(lightboxClose);
    lightbox.appendChild(lightboxTrack);

    // Create a lightbox image for each gallery image
    for (let i = 0; i < galleryItems.length; i += 1) {
      galleryItems[i].setAttribute('data-lightbox-id', i);
      const imgUrl = galleryItems[i].getAttribute('href');
      const img = galleryItems[i].querySelector('img');
      if (imgUrl !== null && img !== null) {
        const lightboxSlide = document.createElement('li');
        lightboxSlide.setAttribute('class', 'splide__slide');
        const lightboxImg = document.createElement('img');
        lightboxImg.setAttribute('alt', img.getAttribute('alt') || '');
        lightboxImg.setAttribute('decoding', 'async');
        lightboxImg.setAttribute('loading', 'lazy');
        lightboxImg.setAttribute('src', imgUrl);
        lightboxSlide.appendChild(lightboxImg);
        lightboxList.appendChild(lightboxSlide);
      }
    }

    document.body.appendChild(lightbox);

    const lightboxSlider = new Splide(lightbox, {
      type: 'fade',
      rewind: true,
      height: '100vh',
      width: '100vw',
      pagination: false,
      keyboard: true,
      paginationKeyboard: true,
      perPage: 1,
      lazyLoad: 'nearby',
    }).mount();

    lightboxSlider.on('dmpOpenLightbox', () => {
      dmpGalleries.openLightbox();
    });

    lightboxSlider.on('dmpCloseLightbox', () => {
      dmpGalleries.closeLightbox();
    });

    dmpGalleries.el.lightbox = lightbox;
    dmpGalleries.el.lightboxSlider = lightboxSlider;
  },
  initializeRegularSlider: (slider) => {
    const sliderEl = slider.querySelector('.splide.gallery');
    if (sliderEl !== null) {
      new Splide(sliderEl, {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        focus: 'center',
        pagination: false,
        gap: '1.5rem',
        breakpoints: {
          600: { perPage: 1 },
        },
      }).mount();
    }
  },
  initializeThumbnailSlider: (slider) => {
    // Initialize thumbnail slider
    let thumbsSplide = null;
    let imageSplide = null;
    const thumbsEl = slider.querySelector('.splide.gallery-thumbnails');
    if (thumbsEl !== null) {
      thumbsSplide = new Splide(thumbsEl, {
        fixedWidth: 100,
        gap: '1rem',
        rewind: true,
        pagination: false,
        isNavigation: true,
        arrows: false,
        dragMinThreshold: {
          mouse: 10,
          touch: 15,
        },
      });
    }

    // Initialize image slider and sync it with the thumbnail slider
    const imageEl = slider.querySelector('.splide.gallery-images');
    if (imageEl !== null) {
      imageSplide = new Splide(imageEl, {
        type: 'fade',
        rewind: true,
        pagination: false,
        lazyLoad: 'nearby',
        // arrow-right2 icon from icomoon.io/app with size 40px.
        arrowPath:
          'M24.268 34.268l12.5-12.5c0.976-0.976 0.976-2.559 0-3.536l-12.5-12.5c-0.976-0.976-2.559-0.976-3.536 0s-0.976 2.559 0 3.536l8.232 8.232h-23.964c-1.381 0-2.5 1.119-2.5 2.5s1.119 2.5 2.5 2.5h23.964l-8.232 8.232c-0.488 0.488-0.732 1.128-0.732 1.768s0.244 1.28 0.732 1.768c0.976 0.976 2.559 0.976 3.536 0z',
      });
    }

    if (thumbsSplide !== null) {
      imageSplide.sync(thumbsSplide);
    }
    if (imageSplide !== null) {
      imageSplide.mount();
    }
    if (thumbsSplide !== null) {
      thumbsSplide.mount();
    }
  },
  closeLightbox: () => {
    dmpGalleries.toggleLightbox(true);
  },
  openLightbox: () => {
    dmpGalleries.toggleLightbox(false);
  },
  navigateLightbox: (imageIndex) => {
    const idx = parseInt(imageIndex, 10) || 0;
    dmpGalleries.el.lightboxSlider.go(idx);
  },
  toggleLightbox: (isOpen = false) => {
    if (isOpen) {
      // Close Lightbox
      dmpGalleries.el.lightbox.classList.remove('dmp-lightbox-visible');
      dmpGalleries.el.lightbox.setAttribute('aria-hidden', true);
      setTimeout(() => {
        // Reset focus to the element that was used to open the lightbox
        dmpGalleries.el.lightboxTriggerEl.focus();
        dmpGalleries.el.lightboxTriggerEl = null;
      });
    } else {
      // Save a reference to the element that triggered the lightbox to open
      dmpGalleries.el.lightboxTriggerEl = document.activeElement;
      // Open Lightbox
      dmpGalleries.el.lightbox.classList.add('dmp-lightbox-visible');
      dmpGalleries.el.lightbox.setAttribute('aria-hidden', false);
      /*
        Set the focus to the lightbox to enable keyboard navigation
        This is done after a short timeout because otherwise the mouse-up or
        key-up event will move the focus back to the clicked/tapped image
        immediately.
        TODO research accessibility issues with this approach
      */
      setTimeout(() => {
        dmpGalleries.el.lightbox.focus();
      }, 200);
    }
  },
};

export default dmpGalleries;
